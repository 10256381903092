<template>
  <div>
    <head-layout  :head-title="$t('cip.dc.DComission.InterfaceCollection')"  :head-btn-options="headBtnOptions" @head-add="addFn" @head-romve="deleteFn">
    </head-layout>
    <!-- <el-container class="new-sino-box"> -->
      <!-- <el-header class="new-sino-header"> -->
        <div class="headers" style="display: flex;">
          <el-input :placeholder="$t('cip.dc.zljkcj.msg.msg1')" v-model="queryList.param1" class="new-sino-input" size="mini" style="width:200px"/>

          <el-button-group style="margin-left: 10px;">
          <el-button size="mini" type="primary" icon="el-icon-search" @click="Searchfn"></el-button>
          <el-button size="mini"  icon="reset-refresh icon-refresh" @click="czFn"></el-button>
        </el-button-group>
        </div>


      <!-- </el-header> -->


      <grid-layout
            ref="gridLayout"
            :data-total="total"
            @page-size-change="handleSizeChange"
            @page-current-change="handleCurrentChange"
            @page-refresh-change="onLoad"
            :page="page"
            @gird-handle-select-click="handleSelectionChange"
            :tableOptions="tableOptions"
            :tableData="tableData"
            :tableLoading="tableLoading"
            :gridRowBtn="gridRowBtn"
            @grid-romve="delRow"
            @grid-edit="handleEdit"
            :searchColumns="searchColumns"
            @grid-execute="toExecute2"

          >
          <template slot="requestMethod" slot-scope="{row}" >
            {{row.requestMode == 1?'get':'post'}}
      </template>

       <template slot="runState" slot-scope="{ row }">
        {{
          row.runState == 1
            ? $t('cip.dc.DComission.run')
            : row.runState == 0
            ? $t('cip.dc.DComission.stop')
            : row.runState
        }}
      </template>

      <template #customBtn="{ row }">
        <el-button @click="toExecute(row)" size="small" type="text">
          {{
            row.runState == 1
              ? $t("datacenter.dataQuality.monitoringTask.pause")
              : $t('cip.dc.DComission.Execute')
          }}

        </el-button>
      </template>



          </grid-layout>


              <el-dialog
      :title="$t('cip.dc.DComission.tip')"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span>{{ dialogtext }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{$t('cip.dc.DComission.false')}}</el-button>
        <el-button
          type="primary"
          @click="SureToExecute"

          >{{$t('cip.dc.DComission.sure')}}</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
    :title="$t('cip.dc.DComission.tip')"
      :visible.sync="dialogExecute"
      width="30%"
      :before-close="handleClose"
    >
      <p>
        {{ dialogtext }}
      </p>

      <el-date-picker
        v-model="ExecuteTime"
        type="daterange"
        value-format="yyyy-MM-dd"
        :range-separator="$t('cip.dc.DComission.to')"
        :start-placeholder="$t('cip.dc.DComission.startdate')"
        :end-placeholder="$t('cip.dc.DComission.enddate')"
      >
      </el-date-picker>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogExecute = false">{{$t('cip.dc.DComission.false')}}</el-button>
        <el-button type="primary" @click="SureToExecute" :loading="Executeloading">{{$t('cip.dc.DComission.sure')}}</el-button>
      </span>
    </el-dialog>



  </div>
</template>

<script>
import {
  itInfList,
  itInfUp,
  itIndel,
  runnewitInf,
  zxnewitInf,
} from "@/api/dataAcquisition/index";
import { mapGetters } from "vuex";
import website from '@/config/website';
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import dialogHeadBtn from "@/views/components/layout/dialog-head-btn";
import formLayout from '@/views/components/layout/form-layout'
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
export default {
  components: {
    HeadLayout,
    GridLayout,
    dialogHeadBtn,
    formLayout
  },
  data() {
    return {
        dialogVisible:false,
        dialogExecute:false,
        ExecuteId:"",
        runState:"",
        ExecuteTime:[],
        Executeloading:false,
      gridRowBtn: [
        {
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "grid-edit",
          remark: 'edit',
          type: "text",
          icon: ""
        },
        {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "grid-romve",
          remark: 'remove',
          type: "text",
          icon: ""
        },
         {
          label: this.$t("datacenter.dataQuality.monitoringTask.implement"),
          emit: "grid-execute",
          remark: "execute",
          type: "text",
          icon: "",
        },

        ],
        tableOptions: {
        customRowAddBtn: false,
        menuWidth: 150,
        column: [
            {
            label: this.$t('cip.dc.DComission.id'),
            prop: "id",
            cell: true,
            width: 100
          },
          {
            label: this.$t('cip.dc.zljkcj.field.InterfaceCode'),
            prop: "infCode",
            cell: true,
            width: 100
          },
          {
            label: this.$t('cip.dc.zljkcj.field.InterfaceName'),
            prop: "infName",
            cell: true,
            width: 200,
            overHidden:true
          },
          {
            label: this.$t('cip.dc.zljkcj.field.InterfaceAddress'),
            prop: "infUrl",
            cell: true,
            width:200,
            overHidden:true
          },
          {
            label: this.$t('cip.dc.zljkcj.field.RequestMethod'),
            prop: "requestMethod",
            cell: true,
            slot:true,

          },


          {
            label: this.$t('cip.dc.zljkcj.field.StartTimeField'),
            prop: "startTimeField",
            cell: true,
            width:150,
          },
          {
            label: this.$t('cip.dc.zljkcj.field.EndTimeField'),
            prop: "endTimeField",
            cell: true,
            width:150,
          },

          {
            label: this.$t('cip.dc.zljkcj.field.IncreaseStepLengthTime'),
            prop: "stepTime",
            width:200,
            cell: true,
          },

          {
            label: this.$t('cip.dc.DComission.enddate'),
            prop: "endTimeField",
            width:200,
            cell: true,
          },

          {
            label: this.$t('cip.dc.DComission.State'),
            prop: "runState",
            cell: true,
            slot: true,
          },









        ],
      },
      page: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0
      },
      tableLoading:false,
      multipleSelection:[],
      tableData: [],
      total:0,
      queryList:{
        query:{
          current:1,
          size:10,
        },
        param1:''
      },
      loading:false,
      headBtnOptions: [{
        label: this.$t("cip.cmn.btn.addBtn"),
        emit: "head-add",
        type: "button",
        icon: ""
      }, {
        label: this.$t("cip.cmn.btn.delBtn"),
        emit: "head-romve",
        type: "button",
        icon: ""
      }],
    };
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.dept_add, false),
        viewBtn: this.vaildData(this.permission.dept_view, false),
        delBtn: this.vaildData(this.permission.dept_delete, false),
        editBtn: this.vaildData(this.permission.dept_edit, false)
      };
    },
  },
  methods: {
    onLoad(page, params = {}) {
      this.loading = true;
      itInfList(this.queryList).then(res => {
        this.loading = false;

        this.tableData = res.data.data.dataList
        this.total = res.data.data.page.totalSize
      });
    },
    addFn(){
      this.$router.push({
        path: '/dataAcquisition/newInterfaceAcAdd',
        query: {
          type: 'add',
        }
      })
    },
    handleEdit(item){
      this.$router.push({
        path: '/dataAcquisition/newInterfaceAcAdd',
        query: {
          type: 'edit',
          id:item.id
        }
      })
    },
    delRow(e){
      let ids = []
        ids[0] = e.id
        this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), this.$t("cip.cmn.btn.delBtn"), {
          confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
          cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
          type: 'warning'
        }).then(() => {

          itIndel(ids).then((res)=>{
          this.$message.success(this.$t("cip.cmn.msg.success.delSuccess"));
          this.onLoad()
        })
        }).catch(() => {

        });

    },
    deleteFn(){
      if(this.multipleSelection.length == 0){
        this.$message.error(this.$t('cip.cmn.msg.warning.tipDetrmineSelectDel'))
        return
      }
      let ids = this.multipleSelection.map((item)=>{
        return item.id
      })
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), this.$t("cip.cmn.btn.delBtn"), {
          confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
          cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: 'warning'
      }).then(() => {
        itIndel(ids).then((res)=>{
          this.$message.success(this.$t("cip.cmn.msg.success.delSuccess"));
          this.onLoad()
        })
      }).catch(() => {

      });

    },
    Searchfn(){
      this.page.pageSize = 10
      this.page.currentPage = 1
      this.queryList.query.current = 1
      this.queryList.query.size = 10
      this.onLoad()
    },
    czFn(){
      this.page.pageSize = 10
      this.page.currentPage = 1

      this.queryList = {

        query:{
          current:1,
          size:10,
        },
        param1:''
      };
      this.onLoad()
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      this.page.pageSize = val.pageSize
      this.queryList.query.current = 1
      this.page.currentPage =1
      this.queryList.query.size = val.pageSize;
      this.onLoad()
    },
    handleCurrentChange(val) {
      this.page.currentPage = val.currentPage
      this.queryList.query.current = val.currentPage;
      this.onLoad()
    },

    toExecute(item) {
      if (item.runState == 0) {
        this.dialogtext = this.$t('cip.dc.DComission.msg1');
        this.btnType = "execute";
      }
      if (item.runState == 1) {
        this.dialogtext = this.$t('cip.dc.DComission.msg2');
        this.btnType = "stop";
      }
      this.dialogVisible = true;
      this.ExecuteId = item.id;

      this.runState = item.runState;
    },

    toExecute2(item) {
      this.ExecuteTime = "";
      this.dialogtext = this.$t('cip.dc.DComission.runtime')+"：";
      this.btnType = "zx";
      this.dialogExecute = true;
      this.ExecuteId = item.id;
      this.runState = item.runState;
    },



    SureToExecute() {
      this.dialogVisible = false;

      if (this.btnType == "zx") {
        // if (this.ExecuteTime) {
          this.Executeloading = true;
          let parms = {
            id:this.ExecuteId,
          }
          if(this.ExecuteTime){
              parms.startTime= this.ExecuteTime[0]
            parms.endTime= this.ExecuteTime[1]
          }
          zxnewitInf(parms)
            .then((res) => {
               this.$message({
                message: this.$t('cip.dc.DComission.msg3'),
                type: "success",
              });
              this.onLoad();
              this.dialogExecute = false;
              this.Executeloading = false;

            })
            .catch((res) => {
              this.dialogExecute = false;
              this.Executeloading = false;
            });
        // } else {
        //   this.$message({
        //     message: "请选择执行时间！",
        //     type: "warning",
        //   });
        // }
      }

      if (this.btnType == "execute") {
        runnewitInf({ id: this.ExecuteId, runState: "1" }).then((res) => {
          this.onLoad();
          this.$message({
            message: this.$t('cip.dc.DComission.msg4'),
            type: "success",
          });
        });
      }
      if (this.btnType == "stop") {
        runnewitInf({ id: this.ExecuteId, runState: "0" }).then((res) => {
          this.onLoad();
          this.$message({
            message:this.$t('cip.dc.DComission.msg5'),
            type: "success",
          });
        });
      }
    },

  },
  created() {
    this.onLoad();
  },
  mounted() {
  },
};
</script>
<style scoped>
.headers{
  background: #FFFFFF;
  padding: 14px 12px;
}
.headers ::v-deep .el-button{
  height: 28px;
}
::v-deep .el-button+.el-button {
  margin-left: 0px;
}
</style>

